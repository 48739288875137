@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .greenShadow {
        @apply shadow-[0px_0px_10px_0px_rgba(0,_150,_121,_0.80)];
    }

    .greenGradientBtn {
        @apply px-8 h-11 bg-gradientGreen font-normal rounded-lg shadow text-white tracking-widest;
    }

    .gradientLinkPinkBtn {
        @apply px-8 h-11 bg-lightGradientPink font-normal flex items-center rounded-lg shadow text-white tracking-widest;
    }
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"] {
        -webkit-appearance: none;
        -moz-appearance: textfield !important;
    }
}

@layer utilities {
}

@font-face {
    font-family: 'EuphoriaScript';
    src: url('../../public/assets/font/EuphoriaScript-Regular.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'AlexBrush';
    src: url('../../public/assets/font/AlexBrush-Regular.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'AnastasiaScript';
    src: url('../../public/assets/font/Anastasia-Script-Personal-Use.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'BigdeyDemo';
    src: url('../../public/assets/font/Bigdey-Demo.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'Daydream';
    src: url('../../public/assets/font/daydream.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'GreatVibes';
    src: url('../../public/assets/font/GreatVibes-Regular.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'Lovely';
    src: url('../../public/assets/font/Lovely.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'Lovelyn';
    src: url('../../public/assets/font/Lovelyn-Free-Personal-Use.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'PinyonScript';
    src: url('../../public/assets/font/PinyonScript-Regular.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'RumbleBrave';
    src: url('../../public/assets/font/Rumble-Brave.otf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'AmsterdamFour';
    src: url('../../public/assets/font/Amsterdam-Four-ttf-400.ttf') format('truetype');;
    font-display: swap;
}

@font-face {
    font-family: 'Amsterdam';
    src: url('../../public/assets/font/Amsterdam.ttf') format('truetype');;
    font-display: swap;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.container-dots {
    display: flex;
    text-align: center;
    align-items: center;
}

.mySwiper {
    height: 100% !important;
}

.mySwiper .swiper-wrapper {
    height: 100% !important;
}

.swiper-pagination-bullet {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    margin: 0 3px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #cea261;
    width: 1.875rem;
    height: 0.75rem;
    border-radius: 20px;
}

.swiper-button-next,
.swiper-button-prev {
    text-rendering: auto;
    color: #cea261 !important;
}

.custom-swier-slider {
    width: 300px !important;
}

.swiper-scrollbar {
    background: #d6d6d6;
}

.swiper-container-horizontal > .swiper-scrollbar {
    border-radius: 2px;
    height: 5px;
    padding: 20px;
    width: 200px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.elementor-item-active::after {
    bottom: 0;
    content: "";
    background-color: #00000021;
    height: 3px;
    width: 100%;
    left: 0;
    z-index: 2;
    position: absolute;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.slider-title {
    -webkit-text-stroke-color: #80a0c2;
    -webkit-text-stroke-width: 1px;
}

.swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.Gallery {
    left: calc(
            (100% / var(--columns) -
            (var(--hgap) * (var(--columns) - 1) / var(--columns))) * var(--column) + (var(--hgap) * var(--column))
    );
    position: absolute;
    width: calc(
            100% / var(--columns) -
            (var(--hgap) * (var(--columns) - 1) / var(--columns))
    );
    top: calc(var(--percent-height) + (var(--items-in-column) * var(--vgap)));
}

/* loader */

.lds-heart {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
}

.lds-heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #f41953;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lds-heart div:after,
.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #f41953;
}

.lds-heart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
}

.lds-heart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
}

.loading-text {
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.z-1 {
    z-index: -1;
}

@keyframes scrolling {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50%);
    }
}

.animate-scrolling {
    animation: scrolling 10s linear infinite;
}

.animate-scrolling:hover {
    animation-play-state: paused;
}

@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}

/* CSS for fade-in effect */
.animate-fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* CSS for fade-out effect */
.animate-fade-out {
    opacity: 1;
    animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* image upload css */

.btn {
    background-color: white;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.uploadBtnWrapper input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

/* .reset-form */
.reset-form .password-reveal::-ms-reveal,
.password-reveal::-ms-clear {
    display: none;
}

.password-reveal {
    letter-spacing: 0.1em;
}

/* guest table */
.guest-table tr th:first-child,
.guest-table td:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
}

.guest-table tr th:nth-child(2),
.guest-table td:nth-child(2) {
    position: sticky;
    left: 100px;
    z-index: 10;
}

/* Bouncy Balls effect */

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.ball {
    width: 5px;
    height: 5px;
    margin-left: 6px;
    background-color: white;
    border-radius: 50%;
    animation: bounce 0.7s ease-in-out infinite;
}

.ball1 {
    animation-delay: -0.2s;
}

.ball2 {
    animation-delay: -0.1s;
}

.ball3 {
    animation-delay: 0s;
}

.changeColorOfIcon {
    color: #13aa81;
}

.changeColorOfIcon:hover {
    color: white;
}

/* quill input css */

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
    content: "9px" !important;
    font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
    content: "10px" !important;
    font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="11px"]::before {
    content: "11px" !important;
    font-size: 11px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
    content: "12px" !important;
    font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14px" !important;
    font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16px" !important;
    font-size: 16px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18px" !important;
    font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
    content: "20px" !important;
    font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="22px"]::before {
    content: "22px" !important;
    font-size: 22px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24px" !important;
    font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="26px"]::before {
    content: "26px" !important;
    font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="28px"]::before {
    content: "28px" !important;
    font-size: 28px !important;
}

/* ripple effect */
span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

/* Ripple effect */

@keyframes ripple-effect {
    to {
        transform: scale(10);
        opacity: 0;
    }
}

/* gallery scroll */

.scrollCustomStyle {
    scroll-behavior: smooth;
    overflow-y: auto;
}

.scrollCustomStyle::-webkit-scrollbar {
    width: 6px;
}

.scrollCustomStyle::-webkit-scrollbar-thumb {
    background: #e5e7f2;
    border-radius: 10px;
}

.scrollCustomStyle::-webkit-scrollbar-track {
    margin-top: 20px;
}

#photo-gallery {
    column-count: 3;
    column-gap: 4px;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Adjust the transition duration as needed */
}

.image-element {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Adjust the transition duration as needed */
}

.fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.fade-in-section {
    opacity: 0.1;
    transform: translateY(5vh);
    visibility: hidden;
    transition: opacity 1000ms ease-out, transform 600ms ease-out,
    visibility 1000ms ease-out;
    will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}

@keyframes showOverlay {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.search-container input::placeholder {
    transition: all 0.5s ease; /* Animation duration */
}

.search-container input:focus::placeholder {
    transform: translateY(-110%); /* Move up animation */
}

@keyframes moveUpwards {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-5px);
    }
}

@keyframes moveDownwards {
    from {
        transform: translateY(-5px);
    }
    to {
        transform: translateY(0);
    }
}

.placeholder-animation {
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}

.move-upwards {
    animation-name: moveUpwards;
}

.move-downwards {
    animation-name: moveDownwards;
}

.filter-scroll::-webkit-scrollbar {
    display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
.filter-scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.animate-slide-up {
    animation: slide-up 0.4s ease-in-out;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.animate-slide-down {
    animation: slide-down 0.4s ease-in-out;
}

@keyframes slide-down {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}

@media (max-width: 1200px) {
    #photo-gallery {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media only screen and (max-width: 1025px) {
    .guest-table tr th:nth-child(2),
    .guest-table td:nth-child(2) {
        position: sticky;
        left: 49px;
        z-index: 10;
        margin-left: 100px;
    }
}

@media (max-width: 800px) {
    #photo-gallery {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 400px) {
    #photo-gallery {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 2;
    }
}

/* To remove dots from swiper slider */
.swiper-pagination-bullets {
    display: none;
}

#mainContent p,
#mainContent li,
#mainContent span {
    font-family: "Outfit";
    font-weight: normal;
}

.typewriter {
    display: inline-block;
    overflow: hidden;
    border-right: 2px solid;
    white-space: nowrap;
    letter-spacing: 2px;
    animation: typing 2s steps(10) infinite alternate;
  }
  
  @keyframes typing {
    from { 
      width: 0;
    }
    to { 
      width: 100%;
    }
  }


  .typewriter-text {
    color: #f34456;
    font-size: 2.3rem;
    font-weight: 600;
    font-family: "Jost";
  }

  @media screen and (max-width: 768px) {
    .typewriter-text {
      font-size: 1.5rem;
    }
  }

  .overflow-visible-carousel {
    overflow: visible;
  }

  .canvas-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 4px;
  }
  
  .design-canvas {
    width: 100%;
    height: auto;
  }
